<template>
<div class="piccolor">
    <div class="tips">
        <span v-if="image_list.length == 0">请点击下方的加号，上传一张需要去除背景的图片，系统将会自动去除背景，请稍等2-3分钟</span>
        <span v-if="image_list.length > 0">已成功添加一张图片</span>
    </div>
    <div class="upload">
        <el-upload class="aaa" action="#" list-type="picture-card" :file-list="image_lists"   :auto-upload="false" :on-success="success" :on-change="on_change" :on-progress="on_process" :before-upload="before_upload" :on-remove="on_remove" :limit=1 auto-upload :http-request="send_image_2_colorful">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                        <i class="el-icon-download"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                        <i class="el-icon-delete"></i>
                    </span>
                </span>
            </div>
        </el-upload>
        <el-dialog v-if="dialogImageUrl">
            <img height="400px" :src="dialogImageUrl" alt="">
        </el-dialog>

    </div>

    <div class="handling">
        <div class="loading" v-if="image_list && image_list.length > 0 && (imageUrl == null || imageUrl.trim().length == 0)">
            <div class="flag generating">
                <span>正在努力生成中。。。</span>
            </div>
            <div class="loading-img">
                <img :src="loading_image" alt="" style="width:600px">
            </div>
        </div>
        <div class="show" v-if="imageUrl != null && imageUrl.length > 0">
            <div class="flag completed">
                <span>已经成功去除背景，快去下方下载成图吧</span>
            </div>
            <div class="modify-image">

                <img class="colorful-image" :src="imageUrl" alt="Colorized Image">
                <!-- <img class="colorful-image" v-lazy="imageUrl" alt="Colorized Image"> -->
            </div>

            <div class="download">
                <a class="reset-btn" @click="reset_btn" @mousedown="qrcode_mouse_down" @mouseup="qrcode_mouse_up" :class="click_reset_btn ? 'click-bg-color' : 'normal-bg_color'">
                    <span>点击重置</span>
                </a>
                <a class="download-img-btn" @click="down_qrcode" @mousedown="qrcode_mouse_down" @mouseup="qrcode_mouse_up" :class="click_down_qrcode ? 'click-bg-color' : 'normal-bg_color'">
                    <span>点击下载去背景图</span>
                </a>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios';
import {
    dataURItoBlob
} from '../common/common.js'
import constant from '../config/constant.js'
export default {
    data() {
        return {
            imageUrl: null,
            dialogImageUrl: '',
            // dialogVisible: false,
            disabled: false,
            click_down_qrcode: false,
            click_reset_btn:false,
            image_list: [],
            image_lists:[],
            loading_image: constant.LOADING_IMAGE,
        };
    },
    methods: {

        async send_image_2_colorful(option) {
            const formData = new FormData();
            formData.append('image', option.file);
            console.log("imagessss", option.file)
            try {
                const response = await axios.post('http://www.soutudashi.com/remove_bg', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                console.log("返回值dddd: ", response.data.data)
                this.imageUrl = response.data.data ; // 假设后端返回的是图像的URL
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        },
        before_upload(file) {
            this.dialogImageUrl = file.url;
        },
        on_process(event, file, fileList) {
            console.log("on_process ", file, fileList)
        },
        on_change(file, fileList) {

            if (fileList && fileList.length > 0) {
                console.log("进入on_change")
                this.image_list = fileList
                this.set_display_none()
            } else {
                this.image_list = []
                this.set_display()
            }
            console.log("onchange ", file, fileList)
            console.log("image_list: ", this.image_list)
            console.log("imageUrl: ", this.imageUrl)
            console.log("ffff: ", this.image_list && this.image_list.length > 0 && (this.imageUrl == null || this.imageUrl.trim().length == 0))

            // console.log("onchange-打印节点",nodes)
        },

        down_qrcode() {
            // console.log("发送二维码")
            const link = document.createElement("a"); // 创建下载链接

            // 从Base64数据创建Blob URL
            const blob = dataURItoBlob(this.imageUrl);
            const url = window.URL.createObjectURL(blob);

            // 设置下载链接的属性
            link.href = url;
            link.download = "去除背景照片.png"; // 指定下载文件的名称

            // 触发点击事件
            document.body.appendChild(link);
            link.click();

            // 下载完成后清理资源
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        },

        qrcode_mouse_down() {
            console.log("点击按下鼠标");
            this.click_down_qrcode = true;
        },

        qrcode_mouse_up() {
            console.log("点击松开鼠标");
            this.click_down_qrcode = false;
        },
        on_remove(file, fileList) {
            console.log("file: ", file, fileList);
            fileList.clearFiles()
        },
        set_display_none() {
            let nodes = document.getElementsByClassName("el-upload--picture-card")
            if (nodes && nodes.length > 0) {
                nodes[0].style.display = 'none';

            }
        },

        set_display() {
            let nodes = document.getElementsByClassName("el-upload--picture-card")
            if (nodes && nodes.length > 0) {
                nodes[0].style.display = null;

            }

        },

        success(response, file, fileList) {
            console.log("上传成功： ", file)
        },
        handleRemove(file) {
            console.log("remov", file);
            file = null
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            // this.dialogVisible = true;
            console.log("preview, ", file)
        },
        handleDownload(file) {
            console.log("down", file);
        },
        onFileChange(e) {
            this.file = e.target.files[0];
            console.log("onchange: ", e.target)
        },
        async uploadImage() {
            const formData = new FormData();
            formData.append('image', this.file);
            console.log("imagessss", this.file)
            try {
                const response = await axios.post('http://www.soutudashi.com/pic_colorful', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                console.log("返回值dddd: ", response.data)
                this.imageUrl = response.data; // 假设后端返回的是图像的URL
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        },

        reset_btn(){
            const nodes = document.getElementsByClassName("reset-btn")
            console.log("rdset: ",nodes)
            if(nodes & nodes.length >0){
             
                nodes[0].style.backgroundColor="#C2185B"
                   console.log("进入设置颜色",nodes)

            }
            console.log("点击进入重制")
            this.image_list = [];
            this.imageUrl = null;
            this.disabled = false;
            this.click_reset_btn = false;
            this.click_down_qrcode = false; 
            this.image_lists = []
            this.set_display();

        }
    }
};
</script>

<style scoped>
/* .colorful-image {
    width: 600px;
} */

.disabled {
    display: none;
}

.download-img-btn {
    width: 200px;
    height: 50px;
    display: block;
    margin: 20px auto;
    line-height: 50px;
    background-color: lightblue;
}

.download-img-btn:hover {
    background-color: lightgreen;
}

/* .el-upload-list__item is-success{
    width: 600 !important;
}

.el-upload-list__item{
  width: 600px;
} */

/* .el-upload-list__item is-ready{
     width: 600px !important;
} */

/* li.el-upload-list__item.is-ready{
    width: 600px !important;
} */
img {
    width: 600px !important;
}

/* li > el-upload-list__item is-ready  {
    background-color: transparent !important;
    border: none !important;
} */
.modify-image,
.loading-img {
    margin-top: 20px;
}

/deep/ li.el-upload-list__item {
    width: 600px !important;
    height: 100% !important;
    border: none !important;
    background-color: transparent !important;
}

.flag.generating {
    width: 600px;
    height: 50px;
    background-color: lightblue;
    margin: 0px auto;
    line-height: 50px;
     border-radius: 5px;
}

.flag.completed {
    width: 600px;
    height: 50px;
    /* background-color: lightgreen; */
    margin: 0px auto;
    line-height: 50px;
    background-color: #90C0A0;
    /* 成功提示背景色，柔和绿色 */
    border-radius: 5px;
}

.tips {
    margin: 10px auto;
    padding: 5px 10px;
    background: rgba(0, 0, 0, .3);
    width: 600px;
    font-size: 18px;

}

/* 下面是ai生成的配色方案 */

/* 基础样式 */
body {
    background-color: #F5F5DC;
    /* 背景色 */
    color: #333333;
    /* 文本色 */
    font-family: 'Times New Roman', Times, serif;
    /* 使用有文艺气息的字体 */
}

.tips {
    background: #6C8E9E;
    /* 提示框背景色，重点色 */
    color: #FFF;
    /* 提示框文本色 */
    border-radius: 8px;
    /* 圆角边框 */
}

/* Element UI组件样式覆盖 */
.el-upload--picture-card {
    /* background-color: #6C8E9E; */
    /* 上传区域背景色，重点色 */
    color: #FFF;
    /* 上传区域文本色 */
}

.reset-btn{
        background-color: #f5b6da;
    /* 按钮背景色，重点色 */
    color: black;
    /* 按钮文本色 */
    transition: background-color 0.3s;
    width: 200px;
    height: 50px;
    margin: 20px auto;
    line-height: 50px;
    font: bold;
}

.reset-btn:hover{
    background-color: #ef0078;
}
/* 按钮样式 */
.download-img-btn {
    background-color: #aaf255;
    /* 按钮背景色，重点色 */
    color: black ;
    font: bold;
    /* 按钮文本色 */
    transition: background-color 0.3s;
    /* 平滑过渡效果 */
}

.download-img-btn:hover {
    background-color:  #75e900;
    /* 鼠标悬浮时的按钮背景色 */
}

/* 加载动画样式 */
.loading .flag {
    background-color: #D3D3D3;
    /* 加载提示背景色，辅助色 */
}

/* 图片样式 */
img.colorful-image {
    /* border: 4px solid #6C8E9E; */
    /* 图片边框，重点色 */
    border-radius: 4px;
    /* 圆角边框 */
    width: 600px;
}

.download{
    display: flex;
    justify-content: center;
    margin: 0px auto;
    width: 600px;
}

/* 其他样式可以根据需要进行调整 */
</style>
